import { ListItem, ListItemIcon, ListItemText, Divider, IconButton, Typography } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
import { Key } from 'react';
import ReactPlayer from 'react-player';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ReplayIcon from '@mui/icons-material/Replay';

// Tutorial playlist item
interface LessionInterface {
  key: Key;
  label: string;
  src: string;
  active: boolean;
  isLast: boolean;
  handlePlayVideo: (label: string) => void;
  playing: boolean;
  isMediaEnded: boolean;
}
export const Lession = (props: LessionInterface) => {
  const { key, label, active, isLast, handlePlayVideo, playing, isMediaEnded } = props;

  const getMediaIcon = () => {
    // when media is ended
    if (active) {
      if (isMediaEnded && !playing) return <ReplayIcon className="icon" />;
      if (!isMediaEnded && playing) return <PauseCircleOutlineIcon className="icon" />;
      if (!isMediaEnded && !playing) return <PlayCircleOutlineIcon className="icon" />;
    } else {
      return <PlayCircleOutlineIcon className="icon" />;
    }
  };

  return (
    <>
      <ListItem
        className="player-item"
        key={key}
        secondaryAction={
          <IconButton edge="end" aria-label="play" onClick={() => handlePlayVideo(label)}>
            {getMediaIcon()}
          </IconButton>
        }
      >
        <ListItemText>
          <Typography className={active ? 'label active-label' : 'label'}>{props.label}</Typography>
        </ListItemText>
      </ListItem>
      {isLast || <Divider component="li" />}
    </>
  );
};

// Render a video player
interface PlayerInterface {
  src?: string | string[];
  playing?: boolean;
  onPause?: () => void;
  onPlay?: () => void;
  onMediaEnd?: () => void;
}

const playerStyles = {
  margin: 0,
  padding: 0,
  borderRadius: '10px',
  overflow: 'hidden'
};

export const VideoPlayer: React.FC<PlayerInterface> = ({ src, playing, onPause, onPlay, onMediaEnd }) => {
  const url = Array.isArray(src) ? src[0] : src || 'https://www.youtube.com/watch?v=LXb3EKWsInQ';

  return <ReactPlayer url={url} width="100%" height="100%" style={playerStyles} playing={playing} controls={true} onPause={onPause} onPlay={onPlay} onEnded={onMediaEnd} />;
};
