import React from 'react';
import { AppBar, Box, Container, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const router = useNavigate();

  const handlePrivacyPolicy = () => {
    window.open('/home/privacy-policy', '_blank');
  };
  const handleHIPAAPolicy = () => {
    window.open('/IMMY-Notice-of-Privacy-Practices-HIPAA.pdf', '_blank');
  };
  return (
    <Box className="footer">
      <Container className="footerContainer" maxWidth="xl">
        <Box className="footer_desc">
          <Typography className="footer_desc_text">
            *For professional use only. ImprimisRx specializes in customizing medications to meet unique patient and practitioner needs. No compounded medication is reviewed by the FDA for safety and efficacy. <br />
            ImprimisRx does not compound essential copies of commercially available products. These ingredients carry risks. Visit{' '}
            <a className="clickable_link" href="https://www.imprimisrx.com/s/product-information" rel="noreferrer" target="_blank">
              {' '}
              imprimisrx.com/productinformation{' '}
            </a>{' '}
            for full product details. References available upon request.
          </Typography>
        </Box>
        <Box className="footer_Copyright">
          <Typography className="footer_Copyright_text">Harrow IP, LLC owns the ImprimisRx registered trademark and the ImprimisRx logo trademark. ©2024 ImprimisRx. All Rights Reserved.</Typography>
        </Box>
        {/* <!-- OneTrust Cookies Settings button start --> */}
        {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button> */}
        {/* <!-- OneTrust Cookies Settings button end --> */}
        {/* <!-- OneTrust Cookies List start --> */}
        {/* <div id="ot-sdk-cookie-policy"></div> */}
        {/* <!-- OneTrust Cookies List end --> */}

        <Box>
          <Typography className="footer_privacy">
            <ul className="footer_privacy_list">
              <li onClick={handlePrivacyPolicy}>Privacy Policy</li>
              <span>|</span>
              <li onClick={handleHIPAAPolicy}>HIPAA Policy</li>
              {/* <span>|</span>
              <li>Cookie Policy</li>
              <span>|</span>
              <li>Social Media Policy</li>
              <span>|</span>
              <li>Terms and Conditions</li> */}
            </ul>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
