import { Box } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }: any) => ({
  height: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

export function LinearLoader() {
  return (
    <Box sx={{ width: '100%' }}>
      <BorderLinearProgress />
    </Box>
  );
}

export function CircularLoader() {
  return (
    <Box className="page d-vh-center" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', color: 'white' }}>
      <CircularProgress color="inherit" />
    </Box>
  );
}
