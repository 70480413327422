import { Stack, Box, Container, Typography } from '@mui/material';
import { VideoPlayer } from '../../../components/MediaPlayer';
import { PromotionalVideo } from '../../../assets/media/hero/hero';

export default function Hero() {
  return (
    <Box className="container">
      <Typography className="heading"> Prescriber Portal </Typography>
      <Box
        className="content"
        sx={{
          p: {
            xs: 0,
            xl: 20
          },
          pt: {
            xs: 0,
            xl: 0
          },
          pb: {
            xs: 0,
            xl: 0
          },
          pl: {
            xs: 0,
            xl: 0
          }
        }}
      >
        <Box className="player-container promotional-video">
          <VideoPlayer playing={false} src={PromotionalVideo} />
        </Box>
      </Box>
    </Box>
  );
}
