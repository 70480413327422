import Chapter1 from './Chapter_1_Intro.mp4';
import Chapter2 from './Chapter_2_Registration_Login.mp4';
import Chapter3 from './Chapter_3_Dashboard_Overview.mp4';
import Chapter4 from './Chapter_4_Patient_Prescription_Tab.mp4';
import Chapter5 from './Chapter_5_Product_Catalog.mp4';
import Chapter6 from './Chapter_6_Settings_Tab.mp4';
import Chapter7 from './Chapter_7_Rx_Status.mp4';
import Chapter8 from './Chapter_8_Submitting_Rx.mp4';
import Chapter9 from './Chapter_9_Outro.mp4';

export const lessions = [
  {
    label: 'Introduction',
    src: Chapter1
  },
  {
    label: 'Registration & Login',
    src: Chapter2
  },
  {
    label: 'Dashboard Overview',
    src: Chapter3
  },
  {
    label: 'Patient Prescription Tab',
    src: Chapter4
  },
  {
    label: 'Product Catalog',
    src: Chapter5
  },
  {
    label: 'Setting Tab',
    src: Chapter6
  },
  {
    label: 'Rx Status',
    src: Chapter7
  },
  {
    label: 'Submitting Rx',
    src: Chapter8
  },
  {
    label: 'Outro',
    src: Chapter9
  }
];
