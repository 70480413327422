import { Stack, Box, Container, Typography, Grid, List, ListItem } from '@mui/material';
import { Lession, VideoPlayer } from '../../../components/MediaPlayer';
import { useEffect, useState } from 'react';
import { lessions } from '../../../assets/media/tutorials-chapters/tutorials';

interface LessionInterface {
  label: string;
  src: string;
}

export default function Tutorials() {
  const [chapterList, setChapterList] = useState<LessionInterface[]>(lessions);
  const [currentLession, setCurrentLession] = useState<LessionInterface>(lessions[0]);
  const [playing, setPlaying] = useState<boolean>(false);
  const [isMediaEnded, setIsMediaEnded] = useState<boolean>(false);

  // Media Helper Fuctions
  const handlePlayVideo = (label: String) => {
    if (label === currentLession.label) {
      //  pause/play the current video
      setPlaying(!playing);
    } else {
      // play the selected video
      setPlaying(false);
      const lession = lessions.find((elem: LessionInterface) => elem.label === label);
      setCurrentLession(lession as LessionInterface);
      setPlaying(true);
    }

    // whenever media is playing or paused, isMediaEnded flag should be false
    setIsMediaEnded(false);
  };

  const handleOnPause = () => {
    setPlaying(false);
    setIsMediaEnded(false);
  };

  const handleOnPlay = () => {
    setPlaying(true);
    setIsMediaEnded(false);
  };

  const handleMediaEnded = () => {
    setIsMediaEnded(true);
    setPlaying(false);
  };

  return (
    <Box className="container">
      <Typography className="heading">Tutorials</Typography>
      <Box className="content">
        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'start'}>
          <Grid item className="player-container tutorial-video" xs={12} sm={12} md={12} lg={8} xl={8}>
            <VideoPlayer src={currentLession.src} playing={playing} onPause={handleOnPause} onPlay={handleOnPlay} onMediaEnd={handleMediaEnded} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <List className="player-list">
              {Array.isArray(chapterList) &&
                chapterList.map((lession: LessionInterface, index: React.Key) => (
                  <Lession {...lession} key={index} active={lession.label === currentLession.label} isLast={lessions.length - 1 === index} handlePlayVideo={(label: string) => handlePlayVideo(label)} playing={playing} isMediaEnded={isMediaEnded} />
                ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
