import { Stack, Box, Container, Typography } from '@mui/material';
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import Hero from './hero';
import Tutorials from './tutorials';
import Faq from './faq';

export default function Help() {
  return (
    <>
      <Stack component="main" className="default-layout">
        <Header />
        <Box className="help-page">
          <Container maxWidth="xl">
            <Box className="main-content-wrap">
              <Hero />
              <Tutorials />
              {/* <Faq /> */}
            </Box>
          </Container>
        </Box>
      </Stack>
      {/* <Footer /> */}
    </>
  );
}
