import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
import { FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText } from '@mui/material';

import { Color } from '../../../interfaces/Color';

export const RadioInput = ({ handler, touched, hasError, meta }: FormArray | FormControlType | FormGroup) => {
  const inputElem = handler('radio');
  inputElem.value = meta.value ?? '';

  const getColor = () => {
    if (touched) {
      if (hasError('required')) {
        return Color.ERROR;
      }
      return Color.SUCCESS;
    }
    return Color.PRIMARY;
  };

  const getError = () => {
    if (touched) {
      if (hasError('required')) {
        return true;
      }
      return false;
    }

    return false;
  };
  return (
    <FormControl className="form-input" sx={{ width: '100%' }}>
      <RadioGroup {...inputElem} row value={meta.defaultValue} color={getColor()}>
        {meta.options &&
          meta.options.map((opt: { label: string; value: string }, index: number) => {
            return <FormControlLabel key={index} value={opt.value} control={<Radio />} label={opt.label} />;
          })}
      </RadioGroup>
      {meta.helperText && getError() && (
        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required" style={{ alignSelf: 'flex-start', marginTop: '0px', marginBottom: '0px' }}>
          {meta.helperText}
        </p>
      )}
    </FormControl>
  );
};
