export const notificationOptions = [
  { label: 'Email', value: 'Email' },
  { label: 'SMS', value: 'SMS' },
  { label: 'Both Email and SMS', value: 'Both' }
];
export const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' }
  // { label: 'Unknown ', value: 'U' }
];

export const specialityOptions = [];

export const hearAboutUs = [
  { label: 'Peer / Co-Worker', value: 'Peer / Co-Worker' },
  { label: 'Sales Representative', value: 'Sales Representative' },
  { label: 'Email Blast', value: 'Email Blast' },
  { label: 'Print Advertisement', value: 'Print Advertisement' },
  { label: 'Search Engine', value: 'Search Engine' },
  { label: 'Conference', value: 'Conference' },
  { label: 'Other', value: 'Other' }
];

export const cityOptions = [
  { label: 'London', value: 'london' },
  { label: 'Delhi', value: 'delhi' },
  { label: 'Kolkata', value: 'kolkata' }
];
export const stateOptions = [
  { label: 'Punjab', value: 'punjab' },
  { label: 'Himachal', value: 'himachal' },
  { label: 'Jammu', value: 'jammu' }
];
