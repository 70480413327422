import { Box, Container, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Stack, Typography, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import Home from '../../../assets/icons/shipping_home.svg';
import { useState } from 'react';

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const DeleteCardConfirmation = (props: { handleClose: (cardId: string, index: number, isDelete: boolean) => void; cardId: string; index: number }) => {
  const handleConfirmation = (confirmation: boolean) => {
    props.handleClose(props.cardId, props.index, confirmation);
  };

  return (
    <>
      <Box component="main" className="card-info">
        <DialogContent>
          <Typography className="modal_heading">Are you sure you want to delete this card?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" sx={{ backgroundColor: '#fff', color: '#00ACBA', border: '1px solid #00ACBA' }} onClick={() => handleConfirmation(false)}>
            No
          </Button>
          <Button variant="contained" sx={{ backgroundColor: '#00ACBA', color: '#fff' }} onClick={() => handleConfirmation(true)}>
            Yes
          </Button>
        </DialogActions>
      </Box>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(props.cardId, props.index, false)}></BootstrapDialogTitle>
    </>
  );
};
